<template>
    <BlockContainer
        :id="'hero-' + slugify(data.title)"
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="
            data.nextBackgroundColor ? data.nextBackgroundColor : null
        "
        :arrow-callout="data.arrowCallout"
        :divider-arrow-class="arrowClasses"
    >
        <div class="pb-16 pt-8 md:pb-16 lg:py-4">
            <div class="grid lg:grid-cols-12 lg:py-12 xl:py-0">
                <div
                    class="relative w-full md:w-auto lg:col-span-7 lg:col-start-1 lg:row-span-2 lg:row-start-1"
                >
                    <div class="flex items-center justify-center">
                        <div
                            class="pointer-events-none absolute z-10 h-[200%] w-[200%] rounded-full bg-gradient-radial from-[rgba(255,255,255,0.5)] to-transparent to-60%"
                        ></div>
                        <StoryblokImage
                            v-if="data.image"
                            class="relative z-20 aspect-[4/3] w-full bg-transparent object-contain"
                            :class="'my-' + imageSpacing"
                            :src="data.image.src"
                            :alt="data.image.alt"
                            sizes="280px md:877px"
                            :quality="95"
                            :image-width="877"
                            :image-height="657"
                        />
                        <div
                            v-else
                            class="h-full w-full rounded bg-white"
                        ></div>
                    </div>
                </div>
                <div
                    class="relative z-20 order-first flex flex-col justify-end lg:col-span-5 lg:ml-20"
                >
                    <h1
                        class="mb-6"
                        :class="
                            data.titleColor ? 'text-' + data.titleColor : null
                        "
                    >
                        {{ data.title }}
                    </h1>
                    <WysiwygContent
                        :content="data.primaryContent"
                        class="mb-6"
                    />
                </div>
                <div class="relative z-20 lg:col-span-5 lg:ml-20">
                    <ButtonContainer v-if="data.buttons">
                        <Button
                            v-for="button in data.buttons"
                            :key="button._uid"
                            :button="button"
                            class="w-full md:w-auto"
                        />
                    </ButtonContainer>
                    <WysiwygContent
                        v-if="data.secondaryContent"
                        class="hero-secondary mb-12 mt-6 text-center md:mb-0 md:text-left"
                        :content="data.secondaryContent"
                    />
                    <!--                    <div class="flex items-center">
                        <WysiwygContent
                            v-if="data.secondaryContent"
                            class="hero-secondary mb-12 mt-6 text-center md:mb-0 md:text-left"
                            :content="data.secondaryContent"
                        />
                        <button class="btn-tertiary h-[44px]">
                            Log In Here
                        </button>
                    </div>-->
                </div>
            </div>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import HeroType from '~/types/HeroType';
import Button from '~/components/page-building/components/Button.vue';
import BlockContainer from '~/components/BlockContainer.vue';
import ButtonContainer from '~/components/ButtonContainer.vue';

const props = defineProps<{
    data: HeroType;
}>();

const arrowClasses = computed<string>(() => {
    if (props.data.arrowCallout) {
        if (props.data.bottomAngle === 'left') {
            if (props.data.arrowCallout.type === 'down-large') {
                return 'left-8 xl:left-[65%] bottom-6 md:-bottom-2 xl:bottom-8';
            }
            if (
                props.data.arrowCallout.type === 'down-curved' ||
                props.data.arrowCallout.type === 'down-small'
            ) {
                return 'left-8 xl:left-[65%] bottom-6 md:-bottom-2 xl:bottom-10';
            }
        } else if (props.data.bottomAngle === 'right') {
            if (props.data.arrowCallout.type === 'down-large') {
                return 'left-8 xl:left-[65%] bottom-6 md:bottom-10 lg:bottom-12 xl:bottom-2';
            }
            if (
                props.data.arrowCallout.type === 'down-curved' ||
                props.data.arrowCallout.type === 'down-small'
            ) {
                return 'left-8 xl:left-[65%] bottom-6 md:bottom-10 lg:bottom-12 xl:bottom-2';
            }
        }

        if (props.data.arrowCallout.type === 'down-large') {
            return 'left-8 xl:left-[65%] bottom-6 md:bottom-10 lg:bottom-12 xl:bottom-2';
        }
        if (
            props.data.arrowCallout.type === 'down-curved' ||
            props.data.arrowCallout.type === 'down-small'
        ) {
            return 'left-8 xl:left-[65%] bottom-6 md:bottom-10 lg:bottom-12 xl:bottom-2';
        }
    }
    return 'left-8 xl:left-[65%] bottom-6 md:-bottom-2 lg:bottom-0 xl:bottom-10';
});

const imageSpacing = computed<number>(() => {
    return 0;
    return props.data.topBottomSpacing ? props.data.topBottomSpacing : 0;
});
</script>

<style>
.hero-secondary a {
    color: black !important;
}
</style>
